require("./bootstrap");

import Sortable from "sortablejs";
import Chart from "chart.js";
import * as Ladda from 'ladda';
import * as ChartAnnotation from "chartjs-plugin-annotation";

window.Sortable = Sortable;
window.Chart = Chart;
window.ChartAnnotation = ChartAnnotation;
window.Ladda = Ladda;

$(document).ready(function() {
    $('.logout-button').on('click', function (e) {
        e.preventDefault();
        $.ajax({
            type: 'POST',
            url: '/logout',
            data: { _token: $('meta[name="csrf-token"]').attr('content') },
            dataType: 'JSON',
            context: this,
            success: function (data) {
                window.location.href = '/'
            },
        });
    });

    $('.prev-user').on('click', function (e) {
        e.preventDefault();
        $.ajax({
            type: 'POST',
            url: '/users/loginBack',
            data: { _token: $('meta[name="csrf-token"]').attr('content') },
            dataType: 'JSON',
            context: this,
            success: function (data) {
                window.location.href = '/'
            },
        });
    });

    $('input, textarea').keydown(function (e) {
        if (e.keyCode == 65 && e.ctrlKey) {
            e.target.select()
        }
    });
});